import { apple, webcreada, google } from '../assets';
import styles, { layout } from '../style';

const Billing = () => (
  <section
    id='work'
    className={layout.sectionReverse}
  >
    <div className={layout.sectionImgReverse}>
      <img
        src={webcreada}
        alt='billing'
        className='w-[100%] h-[100%] relative z-[5]'
      />

      {/* gradient start */}
      <div className='absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient' />
      <div className='absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full orange__gradient' />
      {/* gradient end */}
    </div>

    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        Are you ready to face current technological
        <span className='bg-gradient-to-r from-orange-500 via-rose-700 to-slate-50 rounded-lg'>
          changes?
        </span>
        <br className='sm:block hidden' />
      </h2>
      <p
        className={`${styles.paragraph} max-w-[470px] mt-5`}
      >
        With me as your software engineer, you can be sure
        to have innovative and cutting-edge solutions to
        stay ahead in the market. My 8 years of experience
        in various web technologies allows me to quickly
        adapt to your business needs and provide high
        quality service."
      </p>

      <div className='flex flex-row flex-wrap sm:mt-10 mt-6'>
        <img
          src={apple}
          alt='google_play'
          className='w-[128.86px] h-[42.05px] object-contain mr-5 cursor-pointer'
        />
        <img
          src={google}
          alt='google_play'
          className='w-[144.17px] h-[43.08px] object-contain cursor-pointer'
        />
      </div>
    </div>
  </section>
);

export default Billing;
