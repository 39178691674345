import { card } from '../assets';
import styles, { layout } from '../style';


const CardDeal = () => (
  <section id="lab" className={layout.section}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        LAB <span className='bg-orange-400'>AB</span>{' '}
        <br className='sm:block hidden' />
      </h2>
      <p
        className={`${styles.paragraph} max-w-[470px] mt-5`}
      >
        It is a programming laboratory where I experiment
        with different aspects of software development, such
        as UI/UX, including the creation of prototypes and
        the testing with users to evaluate the effectiveness
        of different options. The goal is to find the best
        solution for a specific problem and improve the user
        experience when using a product or service.
      </p>
    </div>

    <div className={layout.sectionImg}>
      <img
        src={card}
        alt='billing'
        className='w-[100%] h-[100%]'
      />
    </div>
  </section>
);

export default CardDeal;
