import shareVideo from '../assets/videoAB.webm';

const Hero = () => {
  return (
    <section id="home"
      className={`flex md:flex-row flex-col h-screen`}
    >
      <div className=' absolute  w-full h-full bg-clip-content opacity-25'>
        <video
          src={shareVideo}
          type='video/webm'
          loop
          controls={false}
          muted
          autoPlay
          className='w-full h-full object-cover'
        />
      </div>

      <div className='container w-full mx-auto '>
        <div className='text-5xl font-extrabold py-40 '>
          <p className='block bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-white justify-center text-8xl font-extrabold text-center pb-7 '>
            Sr Software Engineer
          </p>
          <p className='bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-rose-700 to-slate-50 justify-center text-2xl text-center mt-10  '>
            Skills= [ Javascript, React, Typescript, Python
            ]
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
