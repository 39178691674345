import styles from './style';
import {
  Footer,
  Navbar,
  Hero,
  Business,
  Billing,
  CardDeal,
  Testimonials,
} from './components';

const App = () => (
  <div className='bg-primary w-full overflow-hidden'>
    <div className='bg-gradient-to-r from-orange-500   w-full overflow-hidden'>
      <div
        className={`${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>

      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero />
        </div>
      </div>

      <div
        className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={`${styles.boxWidth}`}>
          <Business />
          <Billing />
          <CardDeal />
          <Testimonials />
        </div>
      </div>

      <div
        className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
    </div>
  </div>
);

export default App;
