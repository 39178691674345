import {
  people01,
  people02,
  people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  like,
  rocket,
  supercup,
} from '../assets';

export const navLinks = [
  {
    id: 'home',
    title: 'Home',
  },
  {
    id: 'about',
    title: 'About',
  },
  {
    id: 'work',
    title: 'Works',
  },
  {
    id: 'lab',
    title: 'Lab',
  },
  {
    id: 'contact',
    title: 'Contact',
    url: 'https://wa.me/+573190000000',
  }
];

export const features = [
  {
    id: 'feature-1',
    icon: like,
    title: 'Exceeding client expectations',
    content:
      'I pride myself on delivering top-quality work and always going above and beyond to make sure my clients are satisfied.',
  },
  {
    id: 'feature-2',
    icon: rocket,
    title: 'Driving business growth',
    content:
      'I understand the importance of creating visually appealing and user-friendly websites that drive business growth',
  },
  {
    id: 'feature-3',
    icon: supercup,
    title: 'Maximizing online success',
    content:
      'With my help, you can take your online presence to the next level and start seeing real results.',
  },
];

export const feedback = [
  {
    id: 'feedback-1',
    content:
      'I was blown away by Andrés dedication to delivering exactly what I needed. Not only did they have a deep understanding of frontend development, but they were also able to bring my vision to life in a way that exceeded my expectations. I highly recommend Andres to anyone looking for top-notch web development services.',
    name: 'Jessica Johnson',
    title: 'Account executive',
    img: people01,
  },
  {
    id: 'feedback-2',
    content:
      'Working with andrés was an absolute pleasure. They were responsive, professional, and always willing to go above and beyond to make sure I was happy with the end result. The end result was nothing short of amazing and I would recommend andrés to anyone looking for top-quality work.',
    name: 'David Anderson',
    title: 'Founder & Leader',
    img: people02,
  },
  {
    id: 'feedback-3',
    content:
      'As a small business owner, I was looking for someone who could create a professional website on a budget. Andres not only met my budget, but they also delivered a beautiful and functional website that has helped my business grow. Im so grateful for the work andres has done for me and would highly recommend them to anyone in need of web development services',
    name: 'Kames Williams',
    title: 'Teach lead',
    img: people03,
  },
];

export const socialMedia = [
  {
    id: 'social-media-1',
    icon: instagram,
    link: 'https://www.instagram.com/',
  },
  {
    id: 'social-media-2',
    icon: facebook,
    link: 'https://www.facebook.com/',
  },
  {
    id: 'social-media-3',
    icon: twitter,
    link: 'https://www.twitter.com/',
  },
  {
    id: 'social-media-4',
    icon: linkedin,
    link: 'https://www.linkedin.com/',
  },
];
